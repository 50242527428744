import React from "react";
import { Row, Col } from "react-bootstrap";
import imgHero from "../../assets/extra/Products/transaction-categorizer.svg";
import imgI1 from "../../assets/image/svg/merchant.svg";
import imgI2 from "../../assets/image/svg/test.svg";
import imgI3 from "../../assets/image/svg/categorized.svg";
import imgI4 from "../../assets/image/svg/merchant_website.svg";
import imgI5 from "../../assets/image/svg/clean_description.svg";
import NewHero from "./NewHero";

const TransactionData = () => {
  return (
    <Row
      className="align-items-center justify-content-center mb-18 mt-10 rounded-10 pt-8 pb-8 pl-7"
      id="transaction"
      data-aos="fade-up"
      data-aos-duration="1000"
      data-aos-delay="500"
    >
      <Col sm="10" lg="6" className="offset-xl-1  order-lg-2 mb-9 mb-lg-0">
        {/* <div
          className="double-image-group-opacity md-10"
          data-aos="zoom-in"
          data-aos-duration="500"
          data-aos-delay="500"
        >
          <object
            className="main-img  zoom-product-1 mb-12"
            aria-labelledby="trans-data"
            data={imgHero}
            alt=""
          />
        </div> */}
        <NewHero   />
      </Col>
      <Col sm="10" lg="5" className="order-lg-1">
        <div
          className="content-text"
          data-aos="fade-right"
          data-aos-duration="800"
          data-aos-delay="500"
        >
          <h2
            className="gr-text-4 mb-7 justify-content-center"
            style={{ color: "rgb(74, 99, 165)" }}
          >
            Transaction Categorization API
          </h2>
          <p
            className="gr-text-9 mb-0 s transaction-p"
            style={{ textAlign: "left" }}
          >
            Enrich transaction data with all the information your
            users expect in their banking or fintech app.
          </p>
          <Row
            className=" mt-8"
            data-aos="fade-up"
            data-aos-duration="800"
            data-aos-delay="500"
            style={{ textAlign: "left" }}
          >
            <Col md="6" lg="4" sm="4" xs="4" className="mb-12 ml-0 mr-15">
              <div className="feature-widget d-sm-flex">
                <div className="widget-icon gr-text-4 text-blue mr-8 mb-7 mb-md-0">
                  <img className="" src={imgI3} alt="feature" />
                </div>
                <div className="content">
                  <h3 className="title gr-text-7 mb-6">
                    Categorized Transaction
                  </h3>
                </div>
              </div>
            </Col>
            <Col md="4" lg="4" sm="5" xs="5" className="mb-12">
              <div className="feature-widget d-sm-flex">
                <div className="widget-icon gr-text-4 text-blue mr-8 mb-7 mb-md-0">
                  <img className="" src={imgI1} alt="feature" />
                </div>
                <div className="content">
                  <h3 className="title gr-text-7 mb-6">Merchant Logo</h3>
                </div>
              </div>
            </Col>
          </Row>
          <Row
            className=" "
            data-aos="fade-up"
            data-aos-duration="800"
            data-aos-delay="500"
          >
            <Col md="4" lg="4" xs="4" sm="4" className="mb-12 ml-0 mr-15">
              <div className="feature-widget d-sm-flex">
                <div className="widget-icon gr-text-4 text-blue mr-8 mb-7 mb-md-0">
                  <img className="" src={imgI2} alt="feature" />
                </div>
                <div className="content">
                  {/* <Link className='title gr-text-7 mb-6 test-hover-1' to='/carbon-engine'>
										Carbon <br />
										footprint
									</Link> */}
                  <div className="content">
                    <h3 className="title gr-text-7 mb-6">
                      Carbon <br />
                      Footprint
                    </h3>
                  </div>
                </div>
              </div>
            </Col>
            <Col md="2" lg="4" xs="4" sm="4" className="mb-12 ml-0 mr-15">
              <div className="feature-widget d-sm-flex">
                <div className="widget-icon gr-text-4 text-blue mr-8 mb-7 mb-md-0">
                  <img className="" src={imgI4} alt="feature" />
                </div>
                <div className="content">
                  {/* <Link className='title gr-text-7 mb-6 test-hover-1' to='/carbon-engine'>
										Carbon <br />
										footprint
									</Link> */}
                  <div className="content">
                    <h3 className="title gr-text-7 mb-6">Merchant Website</h3>
                  </div>
                </div>
              </div>
            </Col>
            <Col md="4" lg="4" xs="3" sm="4" className="mb-12 ml-0 mr-15">
              <div className="feature-widget d-sm-flex">
                <div className="widget-icon gr-text-4 text-blue mr-8 mb-7 mb-md-0">
                  <img className="" src={imgI5} alt="feature" />
                </div>
                <div className="content">
                  {/* <Link className='title gr-text-7 mb-6 test-hover-1' to='/carbon-engine'>
										Carbon <br />
										footprint
									</Link> */}
                  <div className="content">
                    <h3 className="title gr-text-7 mb-6">Clean Description</h3>
                  </div>
                </div>
              </div>
            </Col>
            {/* <Col md="6" lg="4" className="mb-12">
            <div className="feature-widget d-sm-flex">
              <div className="widget-icon gr-text-4 text-blue mr-8 mb-7 mb-md-0">
                <img className="" src={imgI2} alt="feature" />
              </div>
              <div className="content">
                <h3 className="title gr-text-7 mb-6">Customized categories
</h3>
                
              </div>
            </div>
          </Col> */}
          </Row>
          {/* <Link
						to='txn-categorizer'
						className='btn-link with-icon gr-text-blue gr-text-7 font-weight-bold mt-9'
						data-aos='fade-up'
						data-aos-duration='800'
						data-aos-delay='500'
						style={{ color: '#ff565b' }}>
						More Info <i className='icon icon-tail-right font-weight-bold'></i>
					</Link> */}
        </div>
      </Col>
    </Row>
  );
};

export default TransactionData;
