import React, { useState } from "react";
import {
  Animator,
  ScrollContainer,
  ScrollPage,
  batch,
  Fade,
  FadeIn,
  FadeOut,
  Move,
  MoveIn,
  MoveOut,
  Sticky,
  StickyIn,
  StickyOut,
  Zoom,
  ZoomIn,
  ZoomOut,
} from "react-scroll-motion";
import HomeFeatures from "../sections/landing1/HomeFeatures";

import TxnPhone from "./txns_list.webp";
import TxnCard from "./d_card_1.webp";
import TxnCard2 from "./d_card_2.webp";
import TxnCard3 from "./d_card_3.webp";
import TxnCard4 from "./d_card_4.webp";
import TxnCard5 from "./d_card_5.webp";
import TxnCard6 from "./d_card_6.webp";
import TxnPhoneC from "./txn_phone_c.webp";
import TxnPhoneD from "./txn_phone_d.webp";
import { useRef } from "react";

const ScrollTest = ({ parent }) => {
  const inputRef = useRef();
  var testRef = useRef();
  const ZoomInScrollOut = batch(FadeIn(), ZoomIn());
  const FadeUp = batch(Move(0, -400, 0, 0));
  const Scale = (ratio) => ({
    in: {
      style: {
        transform: (p) => `scale(${p * ratio})`,
      },
    },
    out: {
      style: {
        transform: (p) => (p) => `scale(${p * ratio})`,
      },
    },
  });

  const MoveBack = (ratio) => ({
    in: {
      style: {
        transform: (p) => `translate(${p * 200}px, ${p * 200}px)`,
      },
    },
  });
  const Spin = (cycle) => ({
    in: {
      style: {
        transform: (p) => `rotate(${p * cycle}deg)`,
      },
    },
  });
  const [bgImage, handleImage] = useState(TxnPhoneC);

  return (
    <>
      <ScrollContainer style={{ zIndex: "-2" }} snap={"none"}>
        <ScrollPage style={{ zIndex: -2, position: "relative" }}></ScrollPage>
        <ScrollPage>
          <div
            ref={inputRef}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "white",
            }}
          >
            <ScrollPage>
              <Animator animation={FadeUp}>
                <></>
              </Animator>
            </ScrollPage>
            <ScrollPage>
              <Animator animation={FadeUp}>
                <></>
              </Animator>
            </ScrollPage>
            <ScrollPage>
              <Animator animation={FadeUp}>
                <></>
              </Animator>
            </ScrollPage>
            <ScrollPage>
              <Animator animation={FadeUp}>
                <></>
              </Animator>
            </ScrollPage>
            <ScrollPage style={{ zIndex: "-2" }}>
              <Animator animation={FadeUp}>
                <></>
              </Animator>
            </ScrollPage>

            <span style={{ fontSize: "40px" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "",
                  zIndex: "-2",
                  alignItems: "center",
                  justifyContent: "center",
                  paddingTop: "300px",
                }}
              >
                <Animator
                  animation={batch(Spin(65), MoveIn(600, -1000, 400, 800))}
                  style={{ zIndex: "0" }}
                >
                  <img
                    src={TxnCard}
                    alt="transaction card"
                    style={{
                      position: "absolute",
                      height: "80px",
                      width: "166.258px",
                      transform: "rotate(-65deg)",
                    }}
                  />
                </Animator>

                <Animator
                  animation={batch(Spin(90), MoveIn(-800, -800, 300, 300))}
                  style={{ zIndex: "0" }}
                >
                  <img
                    src={TxnCard2}
                    alt="transaction card"
                    style={{
                      height: "80px",
                      width: "166.258px",
                      position: "absolute",
                      transform: "rotate(-90deg)",
                    }}
                  />
                </Animator>
                <Animator
                  animation={batch(Spin(74), MoveIn(-300, -800, 300, 300))}
                  style={{ zIndex: "0" }}
                >
                  <img
                    src={TxnCard3}
                    alt="transaction card"
                    style={{
                      height: "80px",
                      width: "166.258px",
                      position: "absolute",

                      transform: "rotate(-74deg)",
                    }}
                  />
                </Animator>
                <Animator
                  animation={batch(Spin(65), MoveIn(-1000, -500, 400, 800))}
                  style={{ zIndex: "0" }}
                >
                  <img
                    src={TxnCard4}
                    alt="transaction card"
                    style={{
                      position: "absolute",
                      height: "80px",
                      width: "166.258px",
                      transform: "rotate(-65deg)",
                    }}
                  />
                </Animator>
                <Animator
                  animation={batch(Spin(65), MoveIn(-900, 300, 400, 800))}
                  style={{ zIndex: "0" }}
                >
                  <img
                    src={TxnCard5}
                    alt="transaction card"
                    style={{
                      position: "absolute",
                      height: "80px",
                      width: "166.258px",
                      transform: "rotate(-65deg)",
                    }}
                  />
                </Animator>
                <Animator
                  animation={batch(Spin(65), MoveIn(300, -1000, 400, 800))}
                  style={{ zIndex: "0" }}
                >
                  <img
                    src={TxnCard6}
                    alt="transaction card"
                    style={{
                      position: "absolute",
                      height: "80px",
                      width: "166.258px",
                      transform: "rotate(-65deg)",
                    }}
                  />
                </Animator>
              </div>
              <Animator animation={Scale(1.7)}>
                <img
                  src={bgImage}
                  alt="phone"
                  style={{
                    height: "350px",
                    zIndex: 10000,
                    position: "relative",
                    transform: "translatey(0px)",
                  }}
                />
                <div
                  style={{
                    width: "100%",
                    height: "500px",
                    position: "relative",
                    bottom: "-486px",
                    zIndex: 1,
                  }}
                ></div>
              </Animator>
            </span>
          </div>
        </ScrollPage>
      </ScrollContainer>
    </>
  );
};

export default ScrollTest;
