import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import TypeWriter from "typewriter-effect";
import landing from "../../assets/image/svg/landing.svg";

const Hero = () => {
  return (
    <>
      {/* <!-- Hero Area --> */}
      <div
        className="hero-area position-relative  pt-29 pt-lg-33  pb-lg-35"
        style={{ backgroundColor: "#fcfdfe" }}
      >
        <Container className="landing-large">
          <Row className="justify-content-center  align-items-center">
            <Col
              lg="6"
              md="6"
              className="order-lg-1 mt-9 mt-lg-0 "
              data-aos="fade-right"
              data-aos-duration="500"
              data-aos-once="true"
            >
              <div
                className="hero-content dark-mode-texts landing-text"
                style={{ width: "90%" }}
              >
                <h1
                  className="gr-text-2 mb-9"
                  style={{
                    color: "rgb(74, 99, 165)",
                    fontWeight: "700",
                    minWidth: "40vw",
                  }}
                >
                  <TypeWriter
                    onInit={(typewriter) => {
                      typewriter
                        .changeDelay(80)
                        .typeString(
                          "Transaction categorization and credit scoring APIs."
                        )
                        .start();
                    }}
                  />
                </h1>

                <p
                  className="gr-text-9 pr-5 pl-0 pr-xl-11 py-10"
                  style={{
                    fontWeight: "200",
                    textAlign: "left",
                  }}
                >
                  Genify’s API brings intelligent features to your banking
                  experience. Adopt AI today without complications, using one
                  single API.
                </p>
                <div
                  style={{
                    display: "flex",
                    gap: 20,
                  }}
                >
                  <div className="hero-btn pb-10">
                    <Button
                      href="txn-data-gui"
                      className="gr-hover-y"
                      style={{ backgroundColor: "#ff565b", border: "0" }}
                      // onClick={() =>
                      //   window.dataLayer.push({
                      //     page_type: "homePage",
                      //     event: "request_demo",
                      //   })
                      // }
                    >
                      Try Categorization
                    </Button>
                  </div>
                  <div className="hero-btn pb-10">
                    <Button
                      href="contact-us/#contactform"
                      className="gr-hover-y"
                      style={{
                        backgroundColor: "rgb(74, 99, 165)",
                        border: "0",
                      }}
                      onClick={() =>
                        window.dataLayer.push({
                          page_type: "homePage",
                          event: "request_demo",
                        })
                      }
                    >
                      Get Started
                    </Button>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg="6" md="6" className="order-lg-2 order-sm-2 order-xs-2  ">
              <div
                className="test-landing "
                data-aos="fade-left"
                data-aos-duration="750"
                data-aos-delay="500"
                width="100%"
              >
                <img
                  className="landing-large-image"
                  src={landing}
                  alt="landing image"
                  style={{ width: "100%", height: "80%" }}
                />

                {/* <div className="hero-video-thumb position-relative gr-z-index-1">
                  <a
                    className="video-play-trigger gr-abs-center bg-white circle-xl gr-flex-all-center gr-abs-hover-y focus-reset"
                    href="#/"
                    onClick={(e) => {
                      e.preventDefault();
                      gContext.toggleVideoModal();
                    }}
                    tabIndex="-1"
                  >
                    <i className="icon icon-triangle-right-17-2"></i>
                  </a>
                  <div className="abs-shape gr-abs-tr-custom gr-z-index-n1">
                    <img
                      src={imgShape}
                      alt=""
                      className="w-100"
                      data-aos="zoom-in"
                      data-aos-delay="750"
                      data-aos-duration="1000"
                    />
                  </div>
                </div> */}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Hero;
