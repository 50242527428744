import React from "react";

import { Row, Col } from "react-bootstrap";

import imgI1 from "../../assets/image/svg/alternative-data.svg";
import imgI2 from "../../assets/image/svg/risk.svg";
import imgI3 from "../../assets/image/svg/score-card.svg";
import imgI4 from "../../assets/image/svg/instant-scoring.svg";

import imgHero from "../../assets/extra/Products/credit-scoring.svg";
const CreditScoring = () => {
  return (
    <Row
      className="align-items-center justify-content-center mb-18 mt-10 rounded-10 pt-8 pb-8 pl-7"
      id="credit-scoring"
    >
      {/* style = {{backgroundColor:'#F5F7FA'}} */}
      <Col sm="10" lg="5" className="offset-xl-1  order-lg-1 mb-9 mb-lg-0 pr-7">
        <div
          className="double-image-group-opacity"
          data-aos="zoom-in"
          data-aos-duration="1000"
          data-aos-delay="500"
        >
          <img
            className="main-img zoom-product-3"
            src={imgHero}
            alt="main image"
          />
        </div>
      </Col>
      <Col sm="10" lg="6" className="order-lg-2">
        <div
          className="content-text"
          data-aos="fade-left"
          data-aos-duration="1000"
          data-aos-delay="500"
          // style={{ marginInline: "6vw" }}
        >
          <h2 className="gr-text-4 mb-7" style={{ color: "rgb(74, 99, 165)" }}>
            Credit Scoring API
          </h2>
          <p
            className="gr-text-9 mb-0 py-7 credit-p"
            style={{ textAlign: "left", overflow: "wrap" }}
          >
            Score your loan applicants effortlessly with hosted and customizable
            credit scoring models. Offer financing to unbanked individuals
            assessed via alternative data sources and meeting your risk
            requirements.
          </p>
          <Row
            className="mt-8 flex-no-wrap"
            data-aos="fade-up"
            data-aos-duration="800"
            data-aos-delay="500"
            
          >
            <Col className="mb-12 flex-1 basis-0">
              <div className="feature-widget d-sm-flex">
                <div className="widget-icon gr-text-4 text-blue mr-8 mb-7 mb-md-0">
                  <img className="" src={imgI1} alt="feature" />
                </div>
                <div className="content">
                  <h3 className="title gr-text-7 mb-6">Alternative Data</h3>
                </div>
              </div>
            </Col>
            <Col className="mb-12 flex-1 basis-0">
              <div className="feature-widget d-sm-flex">
                <div className="widget-icon gr-text-4 text-blue mr-8 mb-7 mb-md-0">
                  <img className="" src={imgI2} alt="feature" />
                </div>
                <div className="content">
                  <h3
                    className="title gr-text-7 mb-6"
                    style={{ textAlign: "left" }}
                  >
                    Tailored Risk Profiles
                  </h3>
                </div>
              </div>
            </Col>
          </Row>
          <Row
            className="flex-no-wrap"
            data-aos="fade-up"
            data-aos-duration="800"
            data-aos-delay="500"
          >
            <Col className="mb-12 flex-1">
              <div className="feature-widget d-sm-flex">
                <div className="widget-icon gr-text-4 text-blue mr-8 mb-7 mb-md-0">
                  <img className="" src={imgI4} alt="feature" />
                </div>
                <div className="content">
                  <h3 className="title gr-text-7 mb-6">Instant Scoring</h3>
                </div>
              </div>
            </Col>
            <Col className="mb-12 flex-1">
              <div className="feature-widget d-sm-flex">
                <div className="widget-icon gr-text-4 text-blue mr-8 mb-7 mb-md-0">
                  <img className="" src={imgI3} alt="feature" />
                </div>
                <div className="content">
                  <h3 className="title gr-text-7 mb-6">Detailed Scorecard</h3>
                </div>
              </div>
            </Col>
          </Row>
          {/* <Link
						to='alt-credit'
						className='btn-link with-icon gr-text-blue gr-text-7 font-weight-bold mt-9'
						style={{ float: 'right', color: '#ff565b' }}
						data-aos='fade-up'
						data-aos-duration='800'
						data-aos-delay='500'>
						More Info <i className='icon icon-tail-right font-weight-bold'></i>
					</Link> */}
        </div>
      </Col>
    </Row>
  );
};

export default CreditScoring;
