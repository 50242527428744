import React from "react";
import imgHero from "../../assets/extra/Products/product-recommendation.svg";

import { Row, Col } from "react-bootstrap";

import imgI1 from "../../assets/image/svg/banking-product.svg";
import imgI2 from "../../assets/image/svg/live.svg";
import imgI3 from "../../assets/image/svg/commerce.svg";
import imgI4 from "../../assets/image/svg/multichannel.svg";

const ProductRecommendation = () => {
  return (
    <Row
      className="align-items-center justify-content-center mb-18 mt-10 rounded-10 pt-8 pb-8 pl-7"
      id="product-recommendation"
    >
      <Col sm="10" lg="6" className="offset-xl-1  order-lg-1 mb-9 mb-lg-0">
        <div
          className="double-image-group-opacity"
          data-aos="zoom-in"
          data-aos-duration="1000"
          data-aos-delay="500"
        >
          <img className="main-img w-75" src={imgHero} alt="image hero" />
        </div>
      </Col>
      <Col sm="10" lg="5" className="order-lg-2">
        <div
          className="content-text"
          data-aos="fade-right"
          data-aos-duration="1000"
          data-aos-delay="500"
        >
          <h2
            className="gr-text-4 mb-7"
            style={{ color: "rgb(74, 99, 165)", minWidth: "35vw" }}
          >
            Product Recommendation API
          </h2>
          <p
            className="gr-text-9 mb-0 py-7 product-recommendation-p"
            style={{ textAlign: "left", minWidth: "38vw" }}
          >
            Recommend the right product to the right client at the right time
            for optimal cross-selling. Present relevant and personalized banking
            products and e-commerce offers to your clients across all channels.
          </p>
          <Row
            className=" mt-8"
            data-aos="fade-up"
            data-aos-duration="800"
            data-aos-delay="500"
          >
            <Col md="4" lg="4" sm="4" xs="4" className="mb-12 ml-0 mr-15">
              <div className="feature-widget d-sm-flex">
                <div className="widget-icon gr-text-4 text-blue mr-8 mb-7 mb-md-0">
                  <img className="" src={imgI1} alt="feature" />
                </div>
                <div className="content">
                  <h3 className="title gr-text-7 mb-6">Banking products</h3>
                </div>
              </div>
            </Col>
            <Col md="4" lg="4" sm="2" xs="2" className="mb-12">
              <div className="feature-widget d-sm-flex">
                <div className="widget-icon gr-text-4 text-blue mr-8 mb-7 mb-md-0">
                  <img className="" src={imgI3} alt="feature" />
                </div>
                <div className="content" style={{ width: "400px" }}>
                  <h3
                    className="title gr-text-7 mb-6"
                    style={{ fontWeight: "400px !important", width: "140px" }}
                  >
                    E-commerce offers
                  </h3>
                </div>
              </div>
            </Col>
          </Row>
          <Row
            className=" "
            data-aos="fade-up"
            data-aos-duration="800"
            data-aos-delay="500"
          >
            <Col md="4" lg="4" sm="4" xs="4" className="mb-12 ml-0 mr-15">
              <div className="feature-widget d-sm-flex">
                <div className="widget-icon gr-text-4 text-blue mr-8 mb-7 mb-md-0">
                  <img className="" src={imgI2} alt="feature" />
                </div>
                <div className="content">
                  <h3 className="title gr-text-7 mb-6">Real time</h3>
                </div>
              </div>
            </Col>
            <Col md="4" lg="4" sm="3" xs="3" className="mb-12">
              <div className="feature-widget d-sm-flex">
                <div className="widget-icon gr-text-4 text-blue mr-8 mb-7 mb-md-0">
                  <img className="" src={imgI4} alt="feature" />
                </div>
                <div className="content">
                  <h3 className="title gr-text-7 mb-6">Multi-channel</h3>
                </div>
              </div>
            </Col>
          </Row>
          {/* <Link
						to='rec-engine'
						className='btn-link with-icon gr-text-blue gr-text-7 font-weight-bold mt-9'
						style={{ textAlign: 'right', margin: 'auto', float: 'right', color: '#ff565b' }}
						data-aos='fade-up'
						data-aos-duration='800'
						data-aos-delay='500'>
						More Info <i className='icon icon-tail-right font-weight-bold'></i>
					</Link> */}
        </div>
      </Col>
    </Row>
  );
};

export default ProductRecommendation;
