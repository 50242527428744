import React from "react";
import "./NewHero.css";
import Trans1 from "../../assets/image/transactions/Trans-1-Clean.svg";
import Trans2 from "../../assets/image/transactions/Trans-2-Clean.svg";
import Trans3 from "../../assets/image/transactions/Trans-3-Clean.svg";
import Trans4 from "../../assets/image/transactions/Trans-4-Clean.svg";
import Trans5 from "../../assets/image/transactions/Trans-5-Clean.svg";
import Trans6 from "../../assets/image/transactions/Trans-6-Clean.svg";
import Trans7 from "../../assets/image/transactions/Trans-7-Clean.svg";
import Trans8 from "../../assets/image/transactions/Trans-8-Clean.svg";
import Trans9 from "../../assets/image/transactions/Trans-9-Clean.svg";
import Trans10 from "../../assets/image/transactions/Trans-10-Clean.svg";
import Trans11 from "../../assets/image/transactions/Trans-11-Clean.svg";

const transactions = [
  Trans1,
  Trans2,
  Trans3,
  Trans4,
  Trans5,
  Trans6,
  Trans7,
  Trans8,
  Trans9,
  Trans10,
  Trans11,
];

// height of 1 rec is 180
// mb is 4px for each

const NewHero = () => {
  return (
    <div className="wrapper">
      <h6 className="mb-6">Transcations</h6>
      <div className="animated-scroll-wrapper">
        {/* <img src={Trans1} /> */}
        {/* <div style={{ flex: 1, width: '100%' }}></div> */}
        <div className="animated-rectangles-container">
          {transactions.map((transaction, i) => (
            <div className="animated-rectangle">
              <object
                key={i}
                type="image/svg+xml"
                data={transaction}
                aria-label="Transaction"
                style={{width: "100%"}}
                
                // className="mb-1"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default NewHero;
