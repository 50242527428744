import { Row, Col, Button } from "react-bootstrap";
import EzloanLogo from "../assets/image/ezloan_logo/SVG/Logo_Orange.svg";
import React, { useContext, useRef } from "react";
import { Canvas, useFrame, useLoader } from "@react-three/fiber";
import earthImg from "../assets/world-map.gif";
import GlobalContext from "../context/GlobalContext";
import * as THREE from "three";
const ValueProp = () => {
  const gContext = useContext(GlobalContext);
  const Sphere = () => {
    const base = new THREE.TextureLoader().load(earthImg);
    const ref = useRef();
    useFrame(() => (ref.current.rotation.y += 0.01));
    return (
      <mesh visible castShadow ref={ref}>
        <directionalLight intensity={1} />
        <sphereGeometry attach="geometry" args={[2.9, 32, 32]} />
        <meshBasicMaterial map={base} color="white" />
      </mesh>
    );
  };
  return (
    <Row
      className=" align-items-center  test-value"
      style={{
        backgroundColor: "#4161a6",
        textAlign: "left",
        paddingLeft: "2rem",
        // paddingTop: '2rem',
        marginTop: "2rem",
      }}
    >
      {/* 'linear-gradient(#4161a6, #828dbf)' */}
      <Col lg="6" xl="6" sm="12" xs="12" className="value-prop-m">
        <div className=" mb-7  mt-5 mb-lg-16 pr-lg-30 dark-mode-texts">
          <h2
            className="title gr-text-4 mb-7 "
            style={{ color: "#fff", fontWeight: "700" }}
            data-aos="fade-right"
            data-aos-duration="1000"
            data-aos-delay="500"
          >
            Categorize {"&"} get logo for any merchant in any country{" "}
          </h2>
          <p
            className="gr-text-9 mb-0"
            style={{ color: "#fff", fontWeight: "200" }}
            data-aos="fade-right"
            data-aos-duration="1000"
            data-aos-delay="800"
          >
            Genify’s Transaction Categorization API.
          </p>
        </div>
      </Col>
      <Col
        lg="4"
        xl="4"
        sm="12"
        xs="12"
        style={{ marginTop: "-20px", marginBottom: "20px" }}
      >
        <div className="pt-10">
          <Canvas>
            <ambientLight />
            <Sphere />
          </Canvas>
          <div className="hero-btn pb-10 mt-15">
            <Button
              className="gr-hover-y-test-btn test-btn"
              disabled={false}
              href="/txn-data-gui"
              style={{
                backgroundColor: "#ff565b",
                border: "0",
                padding: "0.25rem",
              }}
              onClick={() =>
                window.dataLayer.push({
                  page_type: gContext.header.path,
                  event: "try_api",
                })
              }
            >
              Try API
            </Button>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default ValueProp;
