import React from "react";

import { Container } from "react-bootstrap";
import TransactionData from "../../components/HomeFeatures/TransactionData";
import FinancialManager from "../../components/HomeFeatures/FinancialManager";
import ProductRecommendation from "../../components/HomeFeatures/ProductRecommendation";
import CreditScoring from "../../components/HomeFeatures/CreditScoring";

const HomeFeatures = () => {
  return (
    <Container className="landing-large">
      <TransactionData />
      {/* <ProductRecommendation /> */}
      {/* <FinancialManager /> */}
      <CreditScoring />
    </Container>
  );
};

export default HomeFeatures;
